<template>
  <!--begin::Quotation Listing-->
  <div class="quotation-template">
    <PageHeaderCount
      moduleType="pre.quotation"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_quotations"
      countkey="pre_quotation_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'quotation-listing pt-0'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0 py-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col
                cols="8"
                class="pt-0 py-0 justify-flex-end d-flex margin-auto"
              >
                <template v-if="getPermission('pre-quotation:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    left
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="bulkActionDialog(item.action, item.title)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="4" class="py-2">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_quotations
                          }}</template>
                          <template v-else>{{
                            item.pre_quotation_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="py-2 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('pre-quotation:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="
                      prospectDialog = true;
                      get_prospects();
                    "
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'quotation',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <template v-if="moreActions.length">
                  <v-menu content-class="custom-menu-list" offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading || exportLoading"
                        class="mx-2 custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        :loading="exportLoading"
                      >
                        <v-icon dark>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in moreActions">
                        <v-list-item
                          :key="index"
                          link
                          :class="{ 'line-active': item.sort }"
                          v-on:click="moreAction(item.action, item.sort)"
                        >
                          <v-list-item-title>
                            <v-icon v-if="item.icon" small left color="cyan">{{
                              item.icon
                            }}</v-icon>
                            {{ item.title }}
                            <template v-if="item.sort">
                              <v-icon
                                v-if="item.sort == 'asc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-ascending</v-icon
                              >
                              <v-icon
                                v-if="item.sort == 'desc'"
                                small
                                right
                                color="cyan"
                                >mdi-sort-descending</v-icon
                              >
                            </template>
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <PageTips module="quotation"></PageTips> -->
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Quotation"
          :basicSearchFields="['barcode', 'reference', 'subject']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="position: static"
        >
          <ListingTable
            :columnCount.sync="defaultColShow.length"
            :dataLoading.sync="dataLoading"
            :rowData.sync="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in defaultColDefs">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit dbx-checkbox': cols.checkbox,
                        'dbx-barcode': cols.field == 'barcode',
                        'dbx-added-at': cols.field == 'added_at',
                        'dbx-updated-at': cols.field == 'updated_at',
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!dataLoading">
                  <template v-if="!lodash.isEmpty(rowData)">
                    <tr v-for="(data, index) in rowData" :key="index" link>
                      <template v-for="cols in defaultColDefs">
                        <td
                          v-if="cols.visible"
                          :key="cols.id"
                          :class="{
                            'dbx-checkbox': cols.checkbox,
                            'dbx-barcode': cols.field == 'barcode',
                            'dbx-added-at': cols.field == 'added_at',
                            'dbx-updated-at': cols.field == 'updated_at',
                            'simple-table-td': !cols.checkbox,
                            'checkbox-width-limit': cols.checkbox,
                          }"
                          :style="{ 'max-width': cols.width }"
                          v-on:click="
                            !cols.checkbox
                              ? rowClicked(data)
                              : 'javascript:void(0)'
                          "
                        >
                          <template v-if="cols.checkbox">
                            <v-checkbox
                              dense
                              v-model="selectedRows"
                              v-bind:value="data.id"
                              color="cyan"
                              class="hide-margin"
                              hide-details
                            ></v-checkbox>
                          </template>
                          <template v-else-if="cols.field == 'barcode'">
                            <Barcode
                              route="pre.quotation.detail"
                              :id="data.id"
                              :barcode="data.barcode"
                            />
                            <v-btn
                              v-if="currentUser.super_admin == 1"
                              class="custom-bold-button"
                              color="red lighten-1 white--text"
                              small
                              text
                              v-on:click.stop.prevent="openDeleteDialog(data)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <template v-else-if="cols.field == 'basic_detail'">
                            <div class="lead-listing">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Title: </b>
                                <ShowValue
                                  :object="data"
                                  object-key="quotation_title"
                                  label="title"
                                ></ShowValue>
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Customer: </b>
                                <ShowValue
                                  :object="data"
                                  object-key="customer.display_name"
                                  label="reference"
                                ></ShowValue>
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Prepared by: </b>
                                <ShowValue
                                  :object="data"
                                  object-key="prepared_by"
                                  label="prepared by"
                                ></ShowValue>
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>CO-Designer: </b>
                                <ShowValue
                                  :object="data"
                                  object-key="assistant_relation.full_name"
                                  label="co-designer"
                                ></ShowValue>
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'status'">
                            <v-chip
                              v-if="data.quote_status_obj"
                              label
                              small
                              class="custom-status font-weight-600 custom-grey-border text-uppercase mx-2"
                              :color="data.quote_status_obj.color"
                              :text-color="data.quote_status_obj.textcolor"
                            >
                              {{ data.quote_status_obj.text }} 
                            </v-chip>
                          </template>
                          <template v-else-if="cols.field == 'dates'">
                            <div class="lead-listing">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Quote Date: </b> {{ formatDate(data.date) }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Open Till: </b>
                                {{ formatDate(data.open_till) }}
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="data.sent_date"
                              >
                                <b>Sent: </b> {{ formatDate(data.sent_date) }}
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'amount'">
                            <div class="lead-listing">
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Optional Total: </b>
                                {{
                                  $accountingJS.formatMoney(data.optional_total)
                                }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Sub Total: </b>
                                {{ $accountingJS.formatMoney(data.sub_total) }}
                              </p>
                              <p
                                v-if="data.tax_amount > 0"
                                class="m-0 custom-nowrap-ellipsis"
                              >
                                <b>GST [{{data.tax_value}}%]: </b>
                                {{ $accountingJS.formatMoney(data.tax_amount) }}
                              </p>
                              <p class="m-0 custom-nowrap-ellipsis">
                                <b>Total: </b>
                                {{ $accountingJS.formatMoney(data.total) }}
                              </p>
                            </div>
                          </template>
                          <template v-else-if="cols.field == 'added_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.added_by)"
                              :data.sync="data"
                            >
                              <template v-slot:display_name>
                                {{ data.added_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.created_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.added_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else-if="cols.field == 'updated_at'">
                            <TableActivity
                              v-if="!lodash.isEmpty(data.updated_by)"
                              :data.sync="data"
                            >
                              <template v-slot:display_name>
                                {{ data.updated_by.display_name }}
                              </template>
                              <template v-slot:date_time>
                                {{ data.modified_at }}
                              </template>
                              <template v-slot:format_date_time>
                                {{ formatDateTime(data.updated_at) }}
                              </template>
                            </TableActivity>
                          </template>
                          <template v-else>
                            <div v-html="printRowData(cols, data)"></div>
                          </template>
                        </td>
                      </template>
                    </tr>
                  </template>
                  <tr v-else-if="!dataLoading">
                    <td :colspan="defaultColDefs.length" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no quotation at the moment.
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :common-dialog="searchDialog">
          <template v-slot:title>Filter Quotation</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="position: static"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <template v-for="(field, index) in searchableArray">                    
                    <template v-if="field == 'prepared_by'">
                      <v-col md="6" :key="index">
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="userList"
                          v-model="objSearch[field]"
                          :label="getFilterLabel(field, 'Quotation')"
                          solo
                          flat
                          item-color="cyan"
                          item-text="display_name"
                          item-value="id"
                          multiple
                          hide-details
                          return-object
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Prepared By Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </template>                  
                    <template v-else-if="field == 'co_designer'">
                      <v-col md="6" :key="index">
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          :items="userList"
                          v-model="objSearch[field]"
                          :label="getFilterLabel(field, 'Quotation')"
                          solo
                          flat
                          item-color="cyan"
                          item-text="display_name"
                          item-value="id"
                          multiple
                          hide-details
                          return-object
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No CO-Designer Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </template>
                    <v-col md="6" :key="index" v-else-if="field != 'dates'">
                      <v-text-field
                        v-model="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Quotation')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <Dialog :common-dialog="prospectDialog">
          <template v-slot:title>
            <v-layout>
              <v-flex> Select Prospect </v-flex>
              <v-flex class="text-right">
                <v-btn icon v-on:click="prospectDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="position: static"
            >
              <v-container fluid class="pt-0">
                <v-text-field
                  ref="prospect"
                  v-model.trim="search_text"
                  :loading="search_loader"
                  autocomplete="off"
                  class="mx-2 mx-md-4 rounded-lg"
                  dense
                  placeholder="Start Typing then press enter"
                  hide-details
                  solo
                  @keydown.esc="get_prospects()"
                  @keydown.enter="get_prospects()"
                  @keydown.tab="get_prospects()"
                >
                  <template #prepend-inner>
                    <v-icon color="grey" class="ml-1 mr-2">
                      mdi-magnify
                    </v-icon>
                  </template>
                </v-text-field>
                <br />
                <v-list class="mx-2 mx-md-4" subheader three-line>
                  <v-skeleton-loader
                    v-if="search_loader"
                    class="custom-skeleton"
                    type="list-item-two-line"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <template v-if="prospects.length > 0">
                      <v-list-item
                        v-for="(data, index) in prospects"
                        v-on:click="select_prospect(data)"
                        :key="index"
                        link
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-500 font-size-16 pt-2"
                          >
                            <Barcode :barcode="data.barcode" small></Barcode>
                            <p class="mb-0 mt-2">Name: {{ data.name }}</p>
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="font-weight-500 font-size-14"
                          >
                            <p class="my-0">
                              Source:
                              <span v-if="data.source">{{
                                data.source.text
                              }}</span>
                            </p>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="font-weight-500 font-size-14"
                          >
                            <p class="my-0">
                              Phone no. {{ data.phone_number }}
                            </p>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="font-weight-500 font-size-14"
                          >
                            <p class="my-0">
                              Lead value:
                              {{ formatCompactNumber(data.lead_value) }}
                            </p>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="align-self-center">
                          <v-list-item-subtitle
                            class="font-weight-500 font-size-14"
                          >
                            <p class="my-0">
                              Lead Date: {{ formatDate(data.lead_date) }}
                            </p>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="font-weight-500 font-size-14"
                          >
                            <p class="my-0">
                              Assigned Date:
                              {{ formatDate(data.date_assigned) }}
                            </p>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="font-weight-500 font-size-14"
                          >
                            <p class="my-0">
                              Last Contacted Date:
                              {{ formatDate(data.last_contact) }}
                            </p>
                          </v-list-item-subtitle>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                    <template v-else>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            class="font-weight-500 font-size-16"
                            >No Prospect(s) Found
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </template>
                </v-list>
              </v-container>
            </perfect-scrollbar>
          </template>
        </Dialog>
        <Dialog :common-dialog="btxBulkDialog">
          <template v-slot:title>Confirmation</template>
          <template v-slot:body>
            <v-container fluid class="pt-0 custom-search-filter">
              <table width="100%">
                <tr>
                  <td width="10%"></td>
                  <td>
                    Are you sure, you want to mark selected items as
                    <span class="font-weight-600">{{ btxBulkText }}</span
                    >?
                  </td>
                </tr>
              </table>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="btxBulkLoading"
              :loading="btxBulkLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="doBulkAction()"
            >
              Yes! Confirm
            </v-btn>
            <v-btn
              :disabled="btxBulkLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="
                btxBulkDialog = false;
                btxBulkAction = null;
              "
            >
              No! Cancel
            </v-btn>
          </template>
        </Dialog>
        <Dialog :common-dialog="delDialog">
          <template v-slot:title>Confirmation</template>
          <template v-slot:body>
            <v-container fluid class="pt-0 custom-search-filter">
              <p class="font-weight-600 black--text">Are you sure, you want to delete Quotation # <Barcode small :barcode="delObj.barcode"/>?</p>
              <p class="my-0 red--text font-size-14">Note: <span class="text-italic">Related summary reports will also be deleted.</span></p>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="delLoading"
              :loading="delLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="red lighten-1 white--text"
              v-on:click="yesDelete()"
            >
              Yes! Delete
            </v-btn>
            <v-btn
              :disabled="delLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="noCancelDelete()"
            >
              No! Cancel
            </v-btn>
          </template>
        </Dialog>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Quotation Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, QUERY, DELETE } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
//import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ShowValue from "@/view/pages/partials/Show-Value.vue";
import ObjectPath from "object-path";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "quotation-list",
  data() {
    return {
      currentUser: {},
      prospectDialog: false,
      exportLoading: false,
      quotationDeleteDialog: false,
      quotationDeleteRequestUrl: "quotations",
      importDialog: false,
      importError: [],
      userList: [],
      file: "",
      dates: [],
      prospects: [],
      dataLoading: false,
      pageModule: "pre-quotation-listing",
      routeAPI: "pre-quotation",
      routeName: "pre.quotation",
      routeDetailName: "pre.quotation.detail",
      status: "all",
      statusList: [],
      moreActions: [
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        /* {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        }, */
        /*{
          title: "Import Quotation(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },*/
      ],
      bulkActions: [
        {
          title: "Mark as Approved",
          action: "mark_as_approved",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Rejected",
          action: "mark_as_rejected",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Draft",
          action: "mark_as_draft",
          icon: "mdi-check-all",
        },
      ],
      search_text: null,
      search_loader: false,
      btxBulkLoading: false,
      btxBulkDialog: false,
      btxBulkAction: null,
      btxBulkText: null,
      delDialog: false,
      delId: 0,
      delObj: { barcode: null },
      delLoading: false,
    };
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      },
    },
  },
  components: {
    ShowValue,
    PageHeaderCount,
    draggable,
    //PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  methods: {
    yesDelete() {
      if(this.currentUser.super_admin == 0){
        return false;
      }
      this.delLoading = true;
      this.$store.dispatch(DELETE, { url: `pre-quotation/${this.delId}` })
        .then(() => {
          this.getRows();
          this.noCancelDelete();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delLoading = false;
        });
    },
    noCancelDelete() {
      this.delDialog = false;
      this.delId = 0;
      this.delObj = { barcode: null };
    },
    openDeleteDialog(row) {
      if(this.currentUser.super_admin == 0){
        return false;
      }
      this.delId = row.id;
      this.delObj = { barcode: row.barcode };
      this.delDialog = true;
    },
    bulkActionDialog(action, text) {
      this.btxBulkDialog = true;
      this.btxBulkAction = action;
      this.btxBulkText = text;
    },
    doBulkAction() {
      this.bulkAction(this.btxBulkAction);
      this.btxBulkDialog = false;
      this.btxBulkAction = null;
      this.btxBulkText = null;
    },
    select_prospect(row) {
      this.prospectDialog = false;
      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("pre.quotation.create", {
            query: {
              prospect: row.id,
            },
          })
        );
      });
    },
    get_prospects() {
      this.search_loader = true;
      this.$store
        .dispatch(QUERY, {
          url: "prospects?status=all&is-quotation=1",
          data: { search: this.search_text },
        })
        .then((response) => {
          this.prospects = ObjectPath.get(response, "data.rows", []);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.search_loader = false;
        });
    },
    bulkAction(action) {
      const _this = this;
      switch (action) {
        case "mark_as_approved":
          _this.updateQuotationStatus(2);
          break;
        case "mark_as_rejected":
          _this.updateQuotationStatus(3);
          break;
        case "mark_as_draft":
          _this.updateQuotationStatus(1);
          break;
      }
    },
    updateQuotationStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "quotations/status",
          data: { quotation: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.QuotationExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("quotation");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
  },
  mounted() {
    this.currentUser = JwtService.currentUser();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Quotation",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "Quotation #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px",
      },
      {
        headerName: "Basic Details",
        field: "basic_detail",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px",
      },
      {
        headerName: "Dates",
        field: "dates",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px",
      },
      {
        headerName: "Amount",
        field: "amount",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
        width: "300px",
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "200px",
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 6,
        width: "170px",
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: true,
        order: 7,
        width: "170px",
      },
    ];

    const defaultColDefs = _this.lodash.filter(
      _this.defaultColDefs,
      function (row) {
        return row.visible;
      }
    );

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
};
</script>
